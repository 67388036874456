import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import GraphQLErrorList from "../components/GraphQLErrorList";
import componentMapper from "../components/componentMapper";
import CustomComponent from "../components/CustomComponent";
import Pricing from "../components/Pricing";

export const query = graphql`
  query pricingPagesQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      ...PageInfo
    }
    offer: pagesOffer(sale_key: { eq: "home" }) {
        ...PriceOffer
    }  
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
    footer: sanitySiteFooter {
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawSlug(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Page = ({ data, errors }) => {
  if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    );
  }

  const { _rawContent, _rawOpenGraph } = data.page;

  const children = _rawContent?.map((block) => {
    if (block._type === "custom") {
      if (block.widget_id === "pricing_card") {
        return <Pricing data={data.offer} />
      }
      return <CustomComponent data={block} />;
    }
    const Component = componentMapper(block._type);
    return <Component key={block._key} data={block} />;
  });

  return (
    <Layout
      title={_rawOpenGraph?.title}
      description={_rawOpenGraph?.description}
      settings={data.settings}
      footer={data.footer}
    >
      {children}
    </Layout>
  );
};

export default Page;
